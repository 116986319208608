/* eslint object-curly-newline: ["error", { "consistent": true }] */

function createElementSVG(el, attributes) {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', el)
  if (attributes) {
    Object.entries(attributes).forEach(e => svg.setAttribute(e[0], e[1]))
  }
  return svg
}

const usFormat = {
  Letter: [8.5, 11],
  Legal: [8.5, 14],
  Government: [8, 10.5],
  Tabloid: [11, 17],
  Ledger: [17, 11],
  Junior: [5, 8],
  'Half Letter': [5.5, 8.5],
  'Credit Card': [2.125, 3.375],
  'Business Card': [2, 3.5],
}

function validUnits(isImperialFirst = false) {
  return isImperialFirst ? ['in', 'mm', 'cm', 'px'] : ['mm', 'cm', 'in', 'px']
}
function validFormats(isImperialFirst = false) {
  const us = Object.keys(usFormat)
  const n = Array(11)
    .fill(null)
    .map((_, i) => i)
  const iso = ['A', 'B', 'C'].map(x => n.map(i => x + i)).flat()
  return isImperialFirst ? [...us, ...iso] : [...iso, ...us]
}

function roundSize(val, unit, round = null) {
  /* eslint no-nested-ternary: "off" */
  const roundF = round === 'round-up' ? Math.ceil : (round === 'round-down' ? Math.floor : Math.round)
  const k = { in: 1000, cm: 100, px: 100 }[unit] || 1
  return (val && roundF(val * k) / k) || 0
}

// convert between sizes of any measurement units
function convertSize(val, from, to, round = null, dpi = 72) {
  if (!from || !to || from === to) return val

  const kTable = {
    in: { in: 1, mm: 25.4, cm: 2.54, px: dpi },
    mm: { in: 1 / 25.4, mm: 1, cm: 1 / 10, px: dpi / 25.4 },
    cm: { in: 1 / 2.54, mm: 10, cm: 1, px: dpi / 2.54 },
    px: { in: 1 / dpi, mm: 25.4 / dpi, cm: 2.54 / dpi, px: 1 },
  }
  const k = kTable[from][to]
  if (!round) return Array.isArray(val) ? val.map(v => v * k) : val * k
  return Array.isArray(val) ? val.map(v => roundSize(v * k, to, round)) : roundSize(val * k, to, round)
}

function isoPageSize(format) {
  const formatZero = { a: 2, b: 4, c: 3 }

  const f = formatZero[format[0].toLowerCase()]
  const n = parseInt(format.slice(1), 10)

  if (!f || Number.isNaN(n)) return null

  const kh = (f - 4 * n) / 8
  const kw = (f - 4 - 4 * n) / 8

  return {
    format,
    width: Math.floor((2 ** kw) * 1000 + 0.2),
    height: Math.floor((2 ** kh) * 1000 + 0.2),
    unit: 'mm',
  }
}
function usPageSize(format) {
  if (format in usFormat) {
    const s = usFormat[format]
    return { format, width: s[0], height: s[1], unit: 'in' }
  }
  return null
}

// returns a css style margin declaration array
// v is either:
//    Number or Array of numbers
//    {value: ( number or array), unit:'str'}
function marginArray(v) {
  const val = v || 0
  let margin

  if (!Array.isArray(val)) margin = Array(4).fill(val)
  else if (val.length === 1) margin = Array(4).fill(val[0])
  else if (val.length === 2) margin = [val[0], val[1], val[0], val[1]]
  else if (val.length === 3) margin = [val[0], val[1], val[2], val[1]]
  else margin = val

  return margin
}

// format must be one of "A4", "Leter', etc.
// or an object in a form {width:w, height:h, unit:"mm"}
function getBoxSize(inSize, toUnit, round, dpi) {
  let size
  if (inSize.format) {
    size = usPageSize(inSize.format) || isoPageSize(inSize.format)
    if (!size) throw new Error(`Invalid page format "${inSize.format}"`)
    if (inSize.portrait === false) { [size.width, size.height] = [size.height, size.width] }
  } else {
    size = { ...inSize }
  }
  size.portrait = size.width <= size.height

  if (toUnit) {
    size.width = convertSize(size.width, size.unit, toUnit, round, dpi)
    size.height = convertSize(size.height, size.unit, toUnit, round, dpi)
    size.unit = toUnit
  }
  return size
}

function getMarginSize(margin, fromUnit, toUnit, round) {
  let ma = marginArray(margin)
  if (toUnit) {
    const from = margin?.unit || fromUnit
    ma = convertSize(ma, from, toUnit, round)
  }
  return ma
}

function mergeDeep(target, ...sources) {
  const isObject = item => (item && typeof item === 'object' && !Array.isArray(item))
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {},
          })
        }
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, {
          [key]: source[key],
        })
      }
    })
  }
  return mergeDeep(target, ...sources)
}

export {
  getBoxSize,
  getMarginSize,
  validUnits,
  validFormats,
  convertSize,
  roundSize,
  createElementSVG,
  mergeDeep,
}
