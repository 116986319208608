var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pmd-container",style:({fontSize: _vm.pageSize.referenceFontSize + 'px'})},[(_vm.showPhantomBlock)?_c('div',{ref:"phantomPage",staticClass:"phantom-block",style:({
      padding: ("" + (_vm.pageSize.padding[0]) + (_vm.pageSize.unit) + " " + (_vm.pageSize.padding[1]) + (_vm.pageSize.unit)),
      width: _vm.pageSize.width + _vm.pageSize.unit,
      height: _vm.pageSize.height + _vm.pageSize.unit,

    })},[_c('div',{attrs:{"id":"menu"}},[(_vm.menu.name || _vm.menu.description)?_c('div',{ref:"menu-head"},[(_vm.menu.name)?_c('div',{staticClass:"print-menu-title"},[_vm._v(" "+_vm._s(_vm.menu.name)+" ")]):_vm._e(),(_vm.menu.description)?_c('div',{staticClass:"print-item-description",style:({
            fontSize: (_vm.settings.item.title.size + _vm.settings.item.description.size)/2 + '%',
            textAlign: _vm.settings.menu.title.textAlign,
          })},[_vm._v(" "+_vm._s(_vm.menu.description)+" ")]):_vm._e()]):_vm._e(),_c('div',{ref:"menu-items"},_vm._l((_vm.menu.children),function(category){return _c('div',{key:category.id},[(category.name || category.description)?_c('div',{ref:"category-head",refInFor:true,style:({ paddingTop: _vm.settings.category.offset + '%' })},[(category.name && _vm.menu.children.length > 1)?_c('div',{staticClass:"print-category-title"},[_vm._v(" "+_vm._s(category.name)+" ")]):_vm._e(),(category.description)?_c('div',{staticClass:"print-item-description",style:({
                fontSize: (_vm.settings.item.title.size + _vm.settings.item.description.size)/2 + '%',
                textAlign: 'start',
              })},[_vm._v(" "+_vm._s(category.description)+" ")]):_vm._e()]):_vm._e(),_c('div',{ref:"category-items",refInFor:true,style:({ width: _vm.categoryItemWidth(category) })},_vm._l((category.children),function(item){return _c('pmd-output-item',{key:item.id,attrs:{"rerender":_vm.rerender,"currency":_vm.currency,"settings":_vm.settings.item,"item":item},on:{"update":function($event){item.el = $event}}})}),1)])}),0)])]):_vm._e(),_c('div',{ref:"print",staticClass:"print-output"})])}
var staticRenderFns = []

export { render, staticRenderFns }