<template>
  <div
    class="pmd-container"
    :style="{fontSize: pageSize.referenceFontSize + 'px'}"
  >
    <div
      v-if="showPhantomBlock"
      ref="phantomPage"
      class="phantom-block"
      :style="{
        padding: `${pageSize.padding[0]}${pageSize.unit} ${pageSize.padding[1]}${pageSize.unit}`,
        width: pageSize.width + pageSize.unit,
        height: pageSize.height + pageSize.unit,

      }"
    >
      <div id="menu">
        <div
          v-if="menu.name || menu.description"
          ref="menu-head"
        >
          <div
            v-if="menu.name"
            class="print-menu-title"
          >
            {{ menu.name }}
          </div>
          <div
            v-if="menu.description"
            class="print-item-description"
            :style="{
              fontSize: (settings.item.title.size + settings.item.description.size)/2 + '%',
              textAlign: settings.menu.title.textAlign,
            }"
          >
            {{ menu.description }}
          </div>
        </div>
        <div
          ref="menu-items"
        >
          <div
            v-for="category in menu.children"
            :key="category.id"
          >
            <div
              v-if="category.name || category.description"
              ref="category-head"
              :style="{ paddingTop: settings.category.offset + '%' }"
            >
              <div
                v-if="category.name && menu.children.length > 1"
                class="print-category-title"
              >
                {{ category.name }}
              </div>
              <div
                v-if="category.description"
                class="print-item-description"
                :style="{
                  fontSize: (settings.item.title.size + settings.item.description.size)/2 + '%',
                  textAlign: 'start',
                }"
              >
                {{ category.description }}
              </div>
            </div>
            <div
              ref="category-items"
              :style="{ width: categoryItemWidth(category) }"
            >
              <pmd-output-item
                v-for="item in category.children"
                :key="item.id"
                :rerender="rerender"
                :currency="currency"
                :settings="settings.item"
                :item="item"
                @update="item.el = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Generated Print Output -->
    <div
      ref="print"
      class="print-output"
    />
  </div>
</template>

<script>
import PmdOutputItem from '@/components/paper-menu-designer/PmdOutputItem.vue'
import PaperMenu from '@/pdm/paperMenu'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
// import htmlToPdfmake from 'html-to-pdfmake'

import html2canvas from 'html2canvas'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'PmdOutput',
  components: {
    PmdOutputItem,
  },
  props: {
    rerender: { type: Boolean, required: true, default: false },
    generate: { type: Boolean, required: true, default: false },
    menu: { type: Object, required: true, default: () => [] },
    pdfTitle: { type: String, required: false, default: '' },
    currency: { type: Object, required: false, default: () => {} },
    pageSize: { type: Object, required: true, default: () => {} },
    settings: { type: Object, required: true, default: () => {} },
  },
  data: () => ({
    showPhantomBlock: true,
    translation: null,
  }),
  watch: {
    rerender(v) {
      if (v) this.updatePage()
    },
    generate(v) {
      if (v) {
        this.generatePdf()
      }
    },
  },
  mounted() {
    this.$emit('render', true)
  },
  methods: {
    categoryItemWidth(category) {
      const cols = this.settings.category.columns
      const len = category.children.length
      const w = cols > 1 ? 100 / (cols < len ? cols : len) - this.settings.category.gap : 100
      return `${w}%`
    },
    generateOutput() {
      const paperMenu = new PaperMenu(this.menu, this.$refs, this.settings)
      paperMenu.generateMenu()
    },
    async generatePdf() {
      await this.updatePage() // with 100% zoom
      const docDefinition = {
        pageSize: { width: this.pageSize.width, height: this.pageSize.height },
        pageOrientation: this.pageSize.portrait ? 'portrait' : 'landscape',
        pageMargins: 0,
        info: { title: this.pdfTitle },
        content: [],
      }
      async function htmlPage2PdfDoc(page, pageBreak) {
        const canvas = await html2canvas(page, {
          scale: 4,
          backgroundColor: null,
          useCORS: true,
          logging: false,
          width: page.offsetWidth,
          height: page.offsetHeight,
        })
        docDefinition.content.push({
          image: canvas.toDataURL('image/png', 1.0),
          absolutePosition: { x: 0, y: 0 },
          width: page.offsetWidth,
          height: page.offsetHeight,
          pageBreak,
        })
      }
      const pages = Array.from(this.$refs.print.children)
      const promises = pages.map((p, i) => htmlPage2PdfDoc(p, i ? 'before' : null))
      Promise.all(promises).then(() => {
        pdfMake.createPdf(docDefinition).open()
        this.$emit('pdfdone', false)
        this.updatePage()
      }).catch(error => error)
    },
    async updatePage() {
      await this.$nextTick()
      while (this.$refs.print.firstChild) this.$refs.print.lastChild.remove()
      this.showPhantomBlock = true
      this.$nextTick(() => {
        this.menu.elements = {
          head: this.$refs['menu-head'] ? this.$refs['menu-head'] : null,
          items: this.$refs['menu-items'],
        }
        this.menu.children.forEach((category, i) => {
          category.elements = { // eslint-disable-line no-param-reassign
            head: this.$refs['category-head'] ? this.$refs['category-head'][i] : null,
            items: this.$refs['category-items'][i],
          }
        })
        this.generateOutput()
        this.showPhantomBlock = false
        this.$emit('render', false)
      })
    },
  },
}
</script>

<style lang="sass">
  .pmd-container
    grid-template-columns: min-content max-content
    .phantom-block
      background: #fdfdfd
    .print-output
      display: flex
      flex-flow: row wrap
      gap: 0.75rem
      .page
        background-color: var(--print-menu-background-color)
</style>
