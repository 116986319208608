export default class StyleSheetInterface {
  prefix

  styleSheet

  constructor(prefix) {
    this.prefix = prefix
    this.styleSheet = StyleSheetInterface.getStyleSheet(prefix)
  }

  static getStyleSheet(prefix) {
    for (let i = 0; i < document.styleSheets.length; i += 1) {
      const s = document.styleSheets[i]
      if (s.prefix === prefix) return s
    }
    const style = document.createElement('style')
    document.head.appendChild(style)
    style.sheet.prefix = prefix
    return style.sheet
  }

  setStyle(className, rules) {
    const selectorText = `.${this.prefix}-${className}`
    const kebabCase = key => key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    const cssValue = Object.entries(rules).map(([k, v]) => `${kebabCase(k)}:${v};`).join('')
    const cssText = `${selectorText} {${cssValue} } `
    const ruleList = this.styleSheet.cssRules
    for (let i = 0; i < ruleList.length; i += 1) {
      if (ruleList[i].selectorText === selectorText) {
        this.styleSheet.deleteRule(i)
        break
      }
    }
    this.styleSheet.insertRule(cssText)
  }
}
