export default class PaperMenu {
  menu

  $refs

  settings

  #pageHeight

  #page

  #pages

  #boxHeight

  constructor(menu, $refs, settings) {
    this.menu = menu
    this.$refs = $refs
    this.settings = settings
    this.#pages = new DocumentFragment()
    const style = window.getComputedStyle(this.$refs.phantomPage)
    this.#pageHeight = this.$refs.phantomPage.offsetHeight - parseInt(style.paddingTop, 10) - parseInt(style.paddingBottom, 10)
    this.#boxHeight = 0
  }

  placeOnPage(element) {
    const h = element.offsetHeight || parseInt(element.style.height, 10)
    if (this.#boxHeight < h) {
      this.createPageElement()
    }
    PaperMenu.placeElement(this.#page, element)
    this.#boxHeight -= h
  }

  static placeElement(parent, child) {
    const el = child.offsetHeight ? child.cloneNode(true) : child
    parent.appendChild(el)
  }

  static createElement(el, style) {
    const element = document.createElement(el)
    Object.assign(element.style, style)
    return element
  }

  createPageElement() {
    const page = this.$refs.phantomPage.cloneNode(false)
    page.classList.add('page')
    page.classList.remove('phantom-block')
    this.#pages.appendChild(page)
    this.#boxHeight = this.#pageHeight
    this.#page = page
  }

  static placeItems(items, settings, maxHeight = Math.MAX_SAFE_INTEGER) {
    const { columns: maxColumn, gap } = settings
    const itemHeights = items.map(i => i.el.offsetHeight)
    // let maxH = items[0].el.offsetHeight
    let maxH = itemHeights.reduce((a, b) => (a > b ? a : b)) // height of the tallest element
    if (maxH > maxHeight) {
      return { content: null, items } // maxH = maxHeight
    }
    const step = itemHeights.reduce((a, b) => (a < b ? a : b)) // height of the shortest element
    let keepTrying
    let columns
    let heights
    let idx
    do {
      keepTrying = false
      columns = []
      columns.push([])
      heights = new Array(maxColumn).fill(0)
      let col = 0
      idx = 0
      while (idx < items.length) {
        const { el } = items[idx]
        if (heights[col] + el.offsetHeight > maxH) {
          columns.push([])
          col += 1
        }
        const colH = heights[col] + el.offsetHeight
        if (col < maxColumn) {
          if (colH <= maxHeight) {
            heights[col] = colH
            columns[col].push(el)
            idx += 1
            if (colH > maxH) maxH = colH
          }
        } else {
          maxH += step
          columns.pop()
          keepTrying = maxH <= maxHeight
          break
        }
      }
    } while (keepTrying)

    const content = PaperMenu.createElement('div', {
      display: 'grid',
      gridTemplateColumns: `repeat(${columns.length}, 1fr)`,
      columnGap: `${gap}%`,
    })
    columns.forEach(col => {
      const column = document.createElement('div')
      col.forEach(i => PaperMenu.placeElement(column, i))
      PaperMenu.placeElement(content, column)
    })
    const height = heights.reduce((a, b) => (a > b ? a : b))
    content.style.height = `${height}px`
    return { content, items: items.slice(idx) }
  }

  generateCategory(node) {
    let headerHeight = (node.elements.head?.offsetHeight || 0)

    let content
    let items = node.children
    while (items.length) {
      const boxHeight = this.#boxHeight - headerHeight;
      ({ content, items } = PaperMenu.placeItems(items, this.settings.category, boxHeight))

      if (content) {
        if (headerHeight) {
          this.placeOnPage(node.elements.head)
          headerHeight = 0
        }
        this.placeOnPage(content)
      } else {
        this.createPageElement()
      }
    }
  }

  /* eslint-disable no-param-reassign */
  generateContentOneColumn(node) {
    if (node.el) {
      this.placeOnPage(node.el)
      return
    }

    if (node.elements.head) this.placeOnPage(node.elements.head)

    node.children.forEach(i => {
      i.el = this.generateContentOneColumn(i)
    })
  }

  generateMenu() {
    const node = this.menu

    if (node.elements.head) this.placeOnPage(node.elements.head)

    node.children.forEach(i => {
      i.el = this.generateCategory(i)
    })
    this.$refs.print.appendChild(this.#pages)
  }
}
/* eslint-enable no-param-reassign */
