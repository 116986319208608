<template>
  <div
    class="print-option"
    :style="{ padding }"
  >
    <div
      v-if="option.name"
      class="print-option-header"
    >
      <span class="print-name">
        {{ option.name }}
        <span
          v-if="!option.children"
          aria-hidden="true"
        /> <!-- leading price dots -->
      </span>
      <span
        v-if="!option.children"
        class="print-price"
      >{{ displayPrice(option.addPrice) }}</span>
    </div>
    <div
      v-if="option.children"
      class="print-option-children"
      :style="{
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        paddingLeft: option.name ? '0ch' : 0,
        columnGap: `${columnGap > 2 ? columnGap : 2}%`
      }"
    >
      <item-options
        v-for=" opt in option.children"
        :key="opt.id"
        :option="opt"
        :offset-top="option.name ? offsetTop / 4 : offsetTop"
        :column-gap="columnGap"
        :is-option-add="isOptionAdd"
        :currency="currency"
        :columns="columns"
        :level="level + 1"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemOptions',
  props: {
    option: { type: Object, required: false, default: () => {} },
    offsetTop: { type: Number, required: false, default: 0 },
    columnGap: { type: Number, required: false, default: 0 },
    isOptionAdd: { type: Boolean, required: false, default: false },
    currency: { type: Object, required: false, default: () => {} },
    columns: { type: Number, required: true, default: 1 },
    level: { type: Number, required: false, default: 0 },
  },
  computed: {
    cols() {
      if (this.level) return 1
      return this.option.children.length < this.columns ? this.option.children.length : this.columns
    },
    padding() {
      const top = this.option.name ? `${this.offsetTop / 2}%` : 0
      const side = this.level ? 0 : `${this.columnGap / this.cols}%`
      return `${top} ${side} 0 ${side}`
    },
  },
  methods: {
    displayPrice(price) {
      const plus = this.isOptionAdd ? '+' : ''
      const p = price.toFixed(2)
      if (!this.currency?.symbol) return p
      return `${this.currency.inFront ? `${plus}${this.currency.symbol}\u2009${p}` : `${plus}${p}\u2009${this.currency.symbol}`}`
    },
  },
}
</script>
<style lang="sass">
.print-option
  display: grid
  grid-template-columns: 1fr
  &-children
    display: grid
</style>
