<template>
  <b-row class="paper-menu-designer">
    <b-col
      :cols="size === 'sm' ? 4 : 4"
      class="paper-menu-designer__controls"
    >
      <b-overlay
        :show="isFetching || isSaving || isGeneratingPdf"
        spinner-variant="primary"
      >
        <div v-if="menu && activePresetId">
          <b-card>
            <h4 class="pb-1">
              {{ $t('_navItem.Paper Menu Designer') }}
              <div style="float: right">
                <b-dropdown
                  v-b-tooltip.hover.top="$t('Zoom')"
                  variant="flat"
                  toggle-class="p-0"
                  no-caret
                  class="ml-3"
                >
                  <template #button-content>
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="ZoomInIcon"
                        role="button"
                        size="20"
                        class="mr-50"
                      />
                      {{ uiSettings.zoom }}%
                    </div>
                  </template>
                  <b-dropdown-form>
                    <b-form-input
                      v-model.number="uiSettings.zoom"
                      type="range"
                      min="25"
                      step="25"
                      size="sm"
                      max="100"
                      @input="rerender = true"
                    />
                  </b-dropdown-form>
                </b-dropdown>
                <feather-icon
                  v-b-tooltip.hover.top="$t('pmdGeneratePDF')"
                  icon="PrinterIcon"
                  size="20"
                  role="button"
                  class="p-0 border-0 ml-1"
                  @click.stop="isGeneratingPdf = true"
                />
                <feather-icon
                  v-b-tooltip.hover.top="$t('Save')"
                  icon="SaveIcon"
                  size="20"
                  role="button"
                  class="p-0 border-0 ml-1"
                  style="display: none"
                />
              </div>
            </h4>
            <h5
              v-b-toggle.control-menu
              class="pt-1 pb-1 border-top"
            >
              {{ $t('Menu') }}
              <feather-icon
                icon="ChevronDownIcon"
                size="20"
                class="ml-1"
              />
            </h5>
            <b-collapse
              id="control-menu"
              visible
              accordion="control-panel"
            >
              <div class="pt-1 pb-1">
                <b-form-row>
                  <b-col v-if="menuNames">
                    <b-form-group
                      :label-size="size"
                      :label="$t('Menu')"
                    >
                      <v-select
                        v-model="uiSettings.menuId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :class="`select-size-${size}`"
                        :clearable="false"
                        :size="size"
                        label="name"
                        :reduce="i => i.id"
                        :options="menuNames"
                        @input="selectMenu"
                      >
                        <template #no-options>
                          {{ $t('The list is empty') }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="translationLanguages"
                    cols="12"
                    xl="4"
                  >
                    <b-form-group
                      :label-size="size"
                      :label="$t('Language')"
                    >
                      <v-select
                        v-model="currentLanguage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :class="`select-size-${size}`"
                        :clearable="false"
                        :searchable="false"
                        label="longName"
                        :reduce="i => i.shortName"
                        :options="translationLanguages"
                        @input="selectMenu"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row align-v="flex-start">
                  <b-col cols="6">
                    <paper-format-selector
                      v-model="pageSize"
                      :label="`${$t('qrLabelPageSize')}`"
                      :imperial-first="imperialFirst"
                      :size="size"
                      @change="rerender = true"
                    />
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label-size="size"
                      :label="$t('qrLabelMargin')"
                    >
                      <b-form-input
                        v-model.number="settings.page.margin"
                        :size="size"
                        type="number"
                        min="0"
                        max="20"
                        @change="rerender = true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                  >
                    <b-form-group
                      :label-size="size"
                      :label="$t('qrLabelBackground')"
                    >
                      <b-input-group :size="size">
                        <b-input-group-prepend is-text>
                          <b-form-checkbox
                            v-model="printBgColor"
                            class="fucked-up-checkbox"
                            @input="setBgColor"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          :value="printBgColor ? settings.page.backgroundColor : null"
                          :type="printBgColor ? 'color' : 'text'"
                          :disabled="!printBgColor"
                          placehoder="none"
                          @input="setBgColor"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </div>
            </b-collapse>
            <template v-if="presets.length > 1">
              <h5
                v-b-toggle.control-presets
                class="pt-1 pb-1 border-top"
              >
                {{ $t('pmdPresets') }}
                <feather-icon
                  icon="ChevronDownIcon"
                  size="20"
                />
              </h5>
              <b-collapse
                id="control-presets"
                accordion="control-panel"
              >
                <div class="paper-menu-designer__presets pb-1">
                  <div
                    v-for="preset in presets"
                    :key="preset.id"
                    :class="[
                      'paper-menu-designer__preset',
                      { 'paper-menu-designer__preset_active': preset.id === activePresetId },
                    ]"
                    @click="setPreset(preset)"
                  >
                    <b-img-lazy
                      :src="require(`@/assets/images/paper-menu/${preset.id}.png`)"
                      fluid
                      class="w-100"
                    />
                  </div>
                </div>
              </b-collapse>
            </template>
            <!-- Fonts -->
            <h5
              v-b-toggle.control-fonts
              class="pt-1 pb-1 border-top"
            >
              {{ $t('Text') }}
              <feather-icon
                icon="ChevronDownIcon"
                size="20"
              />
            </h5>
            <b-collapse
              id="control-fonts"
              accordion="control-panel"
            >
              <div>
                <font-select
                  v-model="settings.menu.title"
                  :label="`${$t('pmdLabelMenuTitle')}`"
                  class="pt-1 pb-1"
                  :size="size"
                  collapsable
                  :weight-options="['normal', 'bold']"
                  font-size-slider
                  :font-size-range="{ min: 30, max: 100, steps: 10 }"
                  @input="setTextStyle('menu-title', $event)"
                />
                <font-select
                  v-model="settings.category.title"
                  :label="`${$t('Category')}`"
                  class="pt-1 pb-1 border-top"
                  :size="size"
                  collapsable
                  :weight-options="['normal', 'bold']"
                  font-size-slider
                  :font-size-range="{ min: 18, max: 38, steps: 10 }"
                  @input="setTextStyle('category-title', $event)"
                />
                <font-select
                  v-model="settings.item.title"
                  :label="`${$t('pmdLabelMenuItemName')}`"
                  class="pt-1 pb-1 border-top"
                  :size="size"
                  collapsable
                  :weight-options="['normal', 'bold']"
                  font-size-slider
                  :font-size-range="{ min: 12, max: 18, steps: 6 }"
                  @input="setTextStyle('item-title', $event)"
                />
                <font-select
                  v-model="settings.item.description"
                  :label="`${$t('pmdLabelMenuItemDescription')}`"
                  class="pt-1 pb-1 border-top"
                  :size="size"
                  collapsable
                  :weight-options="['normal', 'bold']"
                  font-size-slider
                  :font-size-range="{ min: 10, max: 16, steps: 6 }"
                  @input="setTextStyle('item-description', $event)"
                />
                <font-select
                  v-model="settings.option.description"
                  :label="`${$t('pmdLabelOptions')}`"
                  class="pt-1 pb-1 border-top"
                  :size="size"
                  collapsable
                  :weight-options="['normal']"
                  font-size-slider
                  :font-size-range="{ min: 10, max: 14, steps: 4 }"
                  @input="setTextStyle('option-description', $event)"
                />
              </div>
            </b-collapse>
            <h5
              v-b-toggle.control-layout
              class="pt-1 pb-1 border-top"
            >
              {{ $t('pmdLayout') }}
              <feather-icon
                icon="ChevronDownIcon"
                size="20"
                style="float:right"
              />
            </h5>
            <b-collapse
              id="control-layout"
              accordion="control-panel"
            >
              <div class="pt-1 pb-2">
                <span>{{ $t('pmdLabelCategories') }}</span>
                <b-form-row
                  align-v="baseline"
                  class="mt-1"
                >
                  <!--
                <b-col>
                  <b-form-group
                    :label-size="size"
                    label="Columns"
                  >
                    <b-form-input
                      v-model.number="settings.menu.columns"
                      :size="size"
                      type="number"
                      min="1"
                      max="3"
                      @change="rerender = true"
                    />
                  </b-form-group>
                </b-col>
                <b-col class="pl-0">
                  <b-form-group
                    :label-size="size"
                    label="Column Gap"
                  >
                    <b-form-input
                      v-model.number="settings.menu.gap"
                      :size="size"
                      :disabled="settings.menu.columns < 2"
                      type="number"
                      min="0"
                      max="10"
                      @change="rerender = true"
                    />
                  </b-form-group>
                </b-col>
                -->
                  <b-col>
                    <b-form-group
                      :label-size="size"
                      :label="`${$t('pmdLabelVerticalOffset')}`"
                    >
                      <b-form-input
                        v-model.number="settings.category.offset"
                        :size="size"
                        type="number"
                        min="0"
                        max="10"
                        @change="rerender = true"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <hr class="mt-1 mb-1">
                <span>{{ $t('pmdLabelMenuItems') }}</span>
                <b-form-row class="mt-1">
                  <b-col
                    cols="12"
                    xl="4"
                  >
                    <b-form-group
                      :label-size="size"
                      :label="$t('pmdPrintImages')"
                    >
                      <b-form-checkbox
                        v-model="settings.item.image.show"
                        @change="rerender = true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label-size="size"
                      :label="`${$t('pmdLabelImageSize')}`"
                    >
                      <vue-slider
                        v-model="settings.item.image.size"
                        :min="2"
                        :max="6"
                        :disabled="!settings.item.image.show"
                        tooltip="none"
                        @change="rerender = true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label-size="size"
                      :label="`${$t('pmdLabelBorderRadius')}`"
                    >
                      <vue-slider
                        v-model="settings.item.image.radius"
                        :min="0"
                        :max="50"
                        :interval="10"
                        :disabled="!settings.item.image.show"
                        tooltip="none"
                        @change="rerender = true"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row
                  align-v="baseline"
                  class="mt-1"
                >
                  <b-col
                    cols="12"
                    xl="4"
                  >
                    <b-form-group
                      :label-size="size"
                      :label="`${$t('pmdLabelColumns')}`"
                    >
                      <b-form-input
                        v-model.number="settings.category.columns"
                        :size="size"
                        type="number"
                        min="1"
                        max="3"
                        @change="rerender = true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label-size="size"
                      :label="`${$t('pmdLabelColumnGap')}`"
                    >
                      <b-form-input
                        v-model.number="settings.category.gap"
                        :disabled="settings.category.columns < 2"
                        :size="size"
                        type="number"
                        min="0"
                        max="10"
                        @change="rerender = true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label-size="size"
                      :label="`${$t('pmdLabelVerticalOffset')}`"
                    >
                      <b-form-input
                        v-model.number="settings.item.offset"
                        :size="size"
                        type="number"
                        min="0"
                        max="10"
                        @change="rerender = true"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                      :label-size="size"
                      :label="`${$t('pmdLabelPriceLeader')}`"
                    >
                      <v-select
                        v-model="priceLeaderSymbol"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :class="`select-size-${size}`"
                        :clearable="false"
                        :options="priceLeaderOptions"
                        :reduce="i => i.value"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label-size="size"
                      :label="`${$t('pmdLabelLeaderSpacing')}`"
                    >
                      <b-form-input
                        v-model.number="priceLeaderSpacing"
                        :disabled="priceLeaderSymbol === ''"
                        :size="size"
                        type="number"
                        min="0"
                        max="10"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <hr class="mt-1 mb-1">
                <span>{{ $t('pmdLabelItemOptions') }}</span>
                <b-form-row
                  align-v="baseline"
                  class="mt-1"
                >
                  <b-col
                    cols="12"
                    xl="4"
                  >
                    <b-form-group
                      :label-size="size"
                      :label="`${$t('pmdLabelColumns')}`"
                    >
                      <b-form-input
                        v-model.number="settings.item.columns"
                        :size="size"
                        type="number"
                        min="1"
                        max="3"
                        @change="rerender = true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label-size="size"
                      :label="`${$t('pmdLabelVerticalOffset')}`"
                    >
                      <b-form-input
                        v-model.number="settings.item.optionOffset"
                        :size="size"
                        type="number"
                        min="0"
                        max="60"
                        @change="rerender = true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label-size="size"
                      :label="`${$t('pmdLabelHorizontalOffset')}`"
                    >
                      <b-form-input
                        v-model.number="settings.item.gap"
                        :size="size"
                        type="number"
                        min="0"
                        max="20"
                        @change="rerender = true"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </div>
            </b-collapse>
          </b-card>
        </div>
      </b-overlay>
    </b-col>
    <b-col
      ref="pmdContainer"
      :cols="size === 'sm' ? 8 : 8"
      :style="{ display: 'flex', flexFlow: 'column' }"
    >
      <pmd-output
        v-if="menu"
        ref="pmdOutput"
        :rerender="rerender"
        :generate="isGeneratingPdf"
        :currency="currency"
        :menu="menu"
        :pdf-title="pdfTitle"
        :page-size="pageSizePx"
        :settings="settings"
        @render="rerender = $event"
        @pdfdone="isGeneratingPdf = false"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCollapse,
  BRow,
  BCol,
  BFormRow,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormCheckbox,
  BDropdown,
  BDropdownForm,
  BImgLazy,
  VBTooltip,
  VBToggle,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import PmdOutput from '@/components/paper-menu-designer/PmdOutput.vue'
import PaperFormatSelector from '@/components/PaperFormatSelector.vue'
import FontSelect from '@/components/FontSelect.vue'
import { getBoxSize } from '@/qr/utils'
import printMenuPresets from '@/pdm/printMenuPresets'
import VueSlider from 'vue-slider-component'
import StyleSheetInterface from '@/pdm/styleSheetInterface'

import GET_MENU_TRANSLATIONS from '@/gql/query/menu-managment/getMenuTranslations.gql'
import GET_CATEGORY_TRANSLATIONS from '@/gql/query/menu-managment/getCategoryTranslations.gql'
import GET_MENU_ITEM_TRANSLATIONS from '@/gql/query/menu-managment/getMenuItemTranslations.gql'

export default {
  name: 'PaperMenuDesigner',
  components: {
    PmdOutput,
    BOverlay,
    BCard,
    BCollapse,
    BRow,
    BCol,
    BFormRow,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BDropdown,
    BDropdownForm,
    BImgLazy,
    PaperFormatSelector,
    FontSelect,
    VueSlider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  data: () => ({
    size: 'sm',
    uiSettings: {
      menuId: null,
      zoom: 100,
    },
    rerender: false,
    isFetching: true,
    isGeneratingPdf: false,
    isSaving: false,
    currentLanguage: null,
    currency: {},
    translationLanguages: null,
    translations: {},
    imperialFirst: false,
    activePresetId: null,
    menuNames: null,
    menu: null,
    pdfTitle: '',
    printBgColor: true,
    pageSize: {
      format: 'A5',
      portrait: true,
    },
    settings: null,
    priceLeaderSymbol: null,
    priceLeaderSpacing: null,
    styleSheet: null,
  }),
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      currencySymbol: 'restaurant/currencySymbol',
      languages: 'dictionaries/languages',
      isMenusFetched: 'menuManagement/isMenusFetched',
      menus: 'menuManagement/menus',
      isMenuItemsFetched: 'menuManagement/isMenuItemsFetched',
      menuItems: 'menuManagement/menuItems',
    }),
    pageSizePx() {
      const box = getBoxSize(this.pageSize, 'px', 'round')
      const k = this.$refs.pmdContainer.offsetWidth / box.width
      const zoom = this.isGeneratingPdf ? 1 : (this.uiSettings.zoom * k) / 110
      box.width *= zoom
      box.height *= zoom
      const m = this.settings.page.margin / 100
      box.padding = [box.height * m, box.width * m]
      const s = box.width < box.height ? box.width : box.height
      box.referenceFontSize = parseInt(s / 5.95, 10) // to make it about 100% when using A4 paper
      return box
    },
    priceLeaderOptions() {
      return [
        { value: '', label: this.$t('pmdLabelPriceLeaderNone') },
        { value: '.', label: this.$t('pmdLabelPriceLeaderPeriod') },
        { value: '·', label: this.$t('pmdLabelPriceLeaderPoint') },
        { value: '•', label: this.$t('pmdLabelPriceLeaderDot') },
        { value: '-', label: this.$t('pmdLabelPriceLeaderDash') },
        { value: '_', label: this.$t('pmdLabelPriceLeaderUnderscore') },
      ]
    },
  },
  watch: {
    restaurant() {
      this.setData()
      this.getData()
    },
    isMenusFetched() {
      this.setData()
    },
    isMenuItemsFetched() {
      this.setData()
    },
    priceLeaderSymbol(v) {
      this.settings.priceLeaderSymbol = v
      document.documentElement.style.setProperty('--price-leader-symbol', `"${v.repeat(200)}"`)
    },
    priceLeaderSpacing(v) {
      this.settings.priceLeaderSpacing = v
      document.documentElement.style.setProperty('--price-leader-letter-spacing', `${v / 2}ch`)
    },
    rerender() {
      if (!this.$refs.pmdOutput) this.rerender = false
    },
  },
  created() {
    this.presets = printMenuPresets

    const ui = localStorage.getItem('PMD_uiSettings')
    if (ui) {
      this.uiSettings = JSON.parse(ui)
    }

    this.setData()
    this.getData()
  },
  beforeDestroy() {
    const ui = { ...this.uiSettings }
    localStorage.setItem('PMD_uiSettings', JSON.stringify(ui))
  },
  methods: {
    ...mapActions({
      getMenus: 'menuManagement/getMenus',
      getMenuItems: 'menuManagement/getMenuItems',
    }),
    getData() {
      if (!this.restaurant) {
        return
      }

      if (!this.isMenusFetched) {
        this.getMenus()
      }
      if (!this.isMenuItemsFetched) {
        this.getMenuItems()
      }
    },
    setBgColor(v) {
      let color
      if (v === true) color = this.settings.page.backgroundColor
      else if (v === false) color = '#FFFFFF'
      else {
        this.settings.page.backgroundColor = v
        color = v
      }
      document.documentElement.style.setProperty('--print-menu-background-color', color)
    },
    setAllTextStyles() {
      const types = ['menu', 'category', 'item', 'option']
      const kinds = ['title', 'description']
      if (!this.styleSheet) {
        this.styleSheet = new StyleSheetInterface('print')
      }
      types.forEach(type => {
        kinds.forEach(kind => {
          if (this.settings[type]?.[kind]) this.setTextStyle(`${type}-${kind}`, this.settings[type][kind], false)
        })
      })
    },
    setTextStyle(className, style, render = true) {
      const s = {
        fontSize: `${style.size}%`,
        fontFamily: style.family,
        fontStyle: style.style,
        fontWeight: style.weight,
        color: style.color,
        textAlign: style.textAlign,
      }
      this.styleSheet.setStyle(className, s)
      this.rerender = render
    },

    async fetchTranslations(menu) {
      if (this.currentLanguage === this.restaurant.primaryLanguage) {
        return null
      }
      if (this.translations[menu.id]) {
        return this.translations[menu.id]
      }
      const items = menu.categories.flatMap(i => i.items).map(i => i.item)
      const responses = await Promise.all([
        this.$apollo.query({
          query: GET_MENU_TRANSLATIONS,
          variables: {
            getMenuTranslationsId: menu.id,
          },
        }),
        ...menu.categories.map(i => this.$apollo.query({
          query: GET_CATEGORY_TRANSLATIONS,
          variables: {
            getCategoryTranslationsId: i.id,
          },
        })),
        ...items.map(i => this.$apollo.query({
          query: GET_MENU_ITEM_TRANSLATIONS,
          variables: {
            getMenuItemTranslationsId: i.id,
          },
        })),
      ])

      const translations = [
        { id: menu.id, translations: responses[0].data.getMenuTranslations },
        ...responses.filter(i => i.data.getCategoryTranslations).map((i, index) => ({ id: menu.categories[index].id, translations: i.data.getCategoryTranslations })),
        ...responses.filter(i => i.data.getMenuItemTranslations).map((i, index) => ({ id: items[index].id, translations: i.data.getMenuItemTranslations })),
      ]
      this.translations[menu.id] = translations
      return translations
    },

    getTranslation(item, translations) {
      return translations && translations.find(i => i.id === item.id).translations.find(i => i.language === this.currentLanguage)
    },

    translateProperty(item, property, translation) {
      return translation?.[property] || item[property]
    },
    translateDescription(item, translation) {
      return item.content[0].type === 'Text'
        ? translation?.content[0].value || item.content[0].value : null
    },
    translateOptionName(option, translation) {
      return translation?.options?.find(i => i.id === option.id)?.name || option.name
    },
    translateOptionItem(option, optionItem, translation) {
      return translation?.options
        ?.find(i => i.id === option.id)?.items
        ?.find(o => o.id === optionItem.id)?.name || optionItem.name
    },
    getOptionsAdd(item, translation) {
      const sets = []
      const optionsAdd = item.options.filter(i => i.type === 'add').map(i => ({ ...i, name: this.translateOptionName(i, translation), items: i.items }))
      optionsAdd.forEach(oSet => {
        const set = oSet.items.filter(v => v.isEnabled && v.isVisible).map(v => ({ id: v.id, name: this.translateOptionItem(oSet, v, translation), addPrice: v.addPrice || 0 }))
        if (set.length) sets.push({ name: oSet.name, children: set })
      })
      return sets.length ? sets.length === 1 ? sets[0] : { children: sets } : null // eslint-disable-line no-nested-ternary
    },
    getOptionsChoose(item, price, translation) {
      const sets = []

      const oSets = item.options.filter(i => i.type === 'choose')
      for (let i = 0; i < oSets.length; i += 1) {
        const set = oSets[i].items.filter(v => v.isEnabled && v.isVisible).map(v => ({ id: v.id, name: this.translateOptionItem(oSets[i], v, translation), addPrice: v.addPrice || 0 }))
        if (set.length) sets.push(set)
      }
      /* eslint-disable no-param-reassign */
      function mergeChooseSets(parent = null, idx = 0) {
        const isLast = idx === sets.length - 1
        const set = parent ? sets[idx].map(i => ({ ...i, name: isLast ? i.name : `${parent.name}, ${i.name}`, addPrice: parent.addPrice + i.addPrice })) : sets[idx]
        if (!isLast) {
          set.forEach(s => { s.children = mergeChooseSets(s, idx + 1) })
          delete parent?.name
        }
        return set
      }
      /* eslint-enable no-param-reassign */
      if (!sets.length) return null
      sets[0].forEach(s => s.addPrice += price) // eslint-disable-line no-return-assign, no-param-reassign
      return { children: mergeChooseSets() }
    },
    getItems(category, translations) {
      const items = []
      const contentIDs = category.content.filter(i => i.type === 'Offer').map(i => i.id)
      contentIDs.forEach(id => {
        const catItem = category.items.find(i => i.id === id)
        if (catItem.item.isEnabled && catItem.item.isVisible) {
          const item = this.menuItems.find(i => catItem.item.id === i.id)
          const translation = this.getTranslation(catItem.item, translations)
          items.push({
            id,
            type: 'item',
            name: this.translateProperty(item, 'name', translation),
            description: this.translateProperty(item, 'description', translation),
            amount: catItem.amount,
            measurementUnit: catItem.measurementUnit,
            price: catItem.price,
            badge: item.badge,
            tags: item.tags,
            photo: item.images.length ? item.images[0].linkName : null,
            options: {
              choose: this.getOptionsChoose(item, catItem.price, translation),
              add: this.getOptionsAdd(item, translation),
            },
          })
        }
      })
      return items
    },
    getCategories(menu, translations) {
      const categories = []

      const contentIDs = menu.content.filter(i => i.type === 'Category').map(i => i.id)
      contentIDs.forEach(id => {
        const category = menu.categories.find(i => i.id === id)
        const items = this.getItems(category, translations)
        const translation = this.getTranslation(category, translations)
        if (items.length) {
          categories.push({
            id: category.id,
            type: 'category',
            name: this.translateProperty(category, 'name', translation),
            description: this.translateDescription(category, translation),
            children: items,
          })
        }
      })
      return categories
    },
    getMenuNames() {
      const menuNames = this.menus.map(i => ({ id: i.id, name: i.name })) // TODO: filter by active, published, or not deleted
      return menuNames.length ? menuNames : null
    },

    async selectMenu() {
      const menu = this.menus.find(i => i.id === this.uiSettings.menuId)
      const translations = await this.fetchTranslations(menu)

      const translation = this.getTranslation(menu, translations)
      this.menu = {
        id: menu.id,
        type: 'menu',
        name: this.translateProperty(menu, 'name', translation),
        description: this.translateDescription(menu, translation),
        children: this.getCategories(menu, translations),
      }

      this.pdfTitle = `${menu.name}${this.currentLanguage && ` - ${this.currentLanguage}`}`
      this.rerender = true
    },
    setPreset(preset, render = true) {
      this.activePresetId = preset.id

      this.settings = JSON.parse(JSON.stringify(preset.settings))
      this.priceLeaderSymbol = this.settings.priceLeaderSymbol
      this.priceLeaderSpacing = this.settings.priceLeaderSpacing
      this.setBgColor(this.printBgColor && this.settings.page.backgroundColor)
      this.setAllTextStyles()

      this.rerender = render
    },
    setData() {
      if (!this.restaurant || !this.isMenusFetched || !this.isMenuItemsFetched) {
        return
      }

      this.menuNames = this.getMenuNames()
      if (this.menuNames) {
        this.setPreset(this.presets[0], false) // need check user preset or set default

        this.currency = this.restaurant.showCurrencySymbol ? {
          symbol: this.restaurant.showCurrencySymbolInFront ? this.currencySymbol : this.restaurant.primaryCurrency,
          inFront: this.restaurant.showCurrencySymbolInFront,
        } : {}

        this.imperialFirst = this.restaurant.measurementUnits !== 'Metric'
        if (this.imperialFirst) this.pageSize.format = 'Letter'

        if (this.restaurant.translationLanguages.length) {
          this.currentLanguage = this.restaurant.primaryLanguage
          const x = [this.restaurant.primaryLanguage, ...this.restaurant.translationLanguages]
          this.translationLanguages = this.languages.filter(i => x.includes(i.shortName))
        }

        const menu = this.menuNames.find(i => i.id === this.uiSettings.menuId)
        this.uiSettings.menuId = menu?.id || this.menuNames[0].id
        this.selectMenu()
        this.isFetching = false
      }
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/vue/libs/vue-slider.scss'
  @import '@core/scss/vue/libs/vue-select.scss'

  .paper-menu-designer
    --pmd-preset-mt: 4px
    --pmd-preset-mr: 12px
    --pmd-presets-in-row: 4
    &__controls
      h4
        margin: 0
      h5
        margin: 0
        cursor: pointer
        &:hover
          background-color: darken($white, 4%)
        svg
          float: right
          transition: transform 0.35s
      h5.collapsed
        svg
          transform: rotate(-180deg)
      .font-selector
        padding: 0.5rem 0
      .card-footer
          border-top: none
      &__sticky
        position: sticky
        top: calc(1.3rem + #{$navbar-height} + 2rem) // .content.app-content: padding-top, value
      &__presets
        display: flex
        flex-wrap: wrap
        margin-top: calc(1rem - var(--pmd-preset-mt))
      &__preset
        width: calc(100% / var(--pmd-presets-in-row) - (var(--pmd-preset-mr) * calc(var(--pmd-presets-in-row) - 1) / var(--pmd-presets-in-row)))
        margin: var(--pmd-preset-mt) var(--pmd-preset-mr) var(--pmd-preset-mt) 0
        cursor: pointer
        &:nth-child(4n) // --pmd-presets-in-row
          margin-right: 0
        .img-fluid
          border: 1px solid $border-color
          border-radius: $border-radius
        &_active
          .img-fluid
            border-color: $primary
      input[type=number]
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button
          appearance: auto
      .fucked-up-checkbox
        label
          &:before
            border-radius: 3px !important
          &:before, &:after
            left: 0 !important
            top: 0.07rem !important
            width: 1.125rem !important
            height: 1.125rem !important
</style>
