export const googleFonts = { faces: [], subsets: [], categories: [] }

async function getGoogleFonts() {
  const key = process.env.VUE_APP_GOOGLE_KEY
  const response = await fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${key}&capability=WOFF2`)
  if (response.ok) {
    const json = await response.json()
    googleFonts.faces = json.items
    const subsets = new Set()
    const categories = new Set()
    googleFonts.faces.forEach(i => {
      i.subsets.forEach(j => subsets.add(j))
      categories.add(i.category)
    })
    googleFonts.subsets = [...subsets].sort()
    googleFonts.categories = [...categories].sort()
  }
}
getGoogleFonts()

export async function loadFontFace(font, googleFont) {
  const w = font.weight === '400' || font.weight === 'normal' || font.weight === 'regular' ? '' : font.weight
  const s = font.style === 'italic' ? 'italic' : ''
  const variant = !s && !w ? 'regular' : `${w}${s}`

  const gf = googleFont || googleFonts.faces.find(f => f.family === font.family)
  const url = gf.files[variant].replace(/^http:/, 'https:')
  const fontFace = new FontFace(font.family, `url(${url})`, { style: font.style, weight: font.weight })
  await fontFace.load()
  document.fonts.add(fontFace)
  await document.fonts.ready
}

async function getFontData(url) {
  const response = await fetch(url)
  const blob = await response.blob()
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => resolve(reader.result)
  })
}

async function getFont(url) {
  const response = await fetch(url)
  const text = await response.text()
  const regex = /(?<=url\()[^)]+/g
  const fontURLs = [...text.matchAll(regex)].map(x => x[0])
  const fontData = await Promise.all(Array.from(fontURLs, x => getFontData(x)))
  return text.replaceAll(regex, () => fontData.shift())
}

export async function embeddGoogleFonts(fonts) {
  const apiUrl = 'https://fonts.googleapis.com/css2?family='
  const cssContent = []
  await Promise.all(Object.entries(fonts).map(async ([fontName, type]) => {
    const arr = []
    type.style.forEach(s => {
      const style = s === 'italic' ? '1' : '0'
      type.weight.forEach(w => {
        /* eslint no-nested-ternary: "off" */
        arr.push(`${style},${w === 'normal' ? '400' : w === 'bold' ? '700' : w}`)
      })
    })
    const text = type.text ? `&text=${type.text.join('')}` : ''
    const url = `${apiUrl}${fontName.replaceAll(' ', '+')}:ital,wght@${arr.sort().join(';')}${text}`
    cssContent.push(await getFont(url))
  }))
  const style = document.createElementNS('http://www.w3.org/2000/svg', 'style')
  style.textContent = cssContent.join('')
  return style
}
