export default [
  {
    id: 1,
    settings: {
      priceLeaderSymbol: '.',
      priceLeaderSpacing: 2,
      page: {
        margin: 5,
        backgroundColor: '#FBFAE9',
      },
      menu: {
        columns: 1,
        gap: 3,
        title: {
          family: 'Noto Serif Display',
          style: 'normal',
          weight: '400',
          textAlign: 'center',
          size: 30,
          color: '#800020',
        },
      },
      category: {
        offset: 3,
        columns: 1,
        gap: 3,
        title: {
          family: 'Noto Serif Display',
          style: 'normal',
          weight: '400',
          size: 22.5,
          textAlign: 'center',
          color: '#808000',
        },
      },
      item: {
        offset: 3,
        columns: 2,
        gap: 3,
        title: {
          family: 'Noto Serif Display',
          style: 'normal',
          weight: '400',
          textAlign: 'left',
          size: 15,
          color: '#2F4F4F',
        },
        description: {
          family: 'Noto Serif Display',
          style: 'normal',
          weight: '400',
          textAlign: 'left',
          size: 10,
          color: '#2F4F4F',
        },
        image: {
          show: true,
          size: 2,
          radius: 10,
        },
        optionOffset: 3,
      },
      option: {
        description: {
          family: 'Noto Serif Display',
          style: 'normal',
          weight: '400',
          size: 10,
          color: '#2F4F4F',
        },
      },
    },
  },
]
