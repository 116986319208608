<template>
  <div>
    <b-form-group
      :label="componentLabel"
      :label-size="size"
    >
      <v-select
        :class="`select-size-${size}`"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :clearable="false"
        :options="paperFormats"
        :value="getFormatValueForSelect()"
        @input="onInputFormat($event)"
      >
        <template #no-options>
          {{ $t('The list is empty') }}
        </template>
      </v-select>
      <b-form-radio-group
        v-if="value.format"
        v-model="value.portrait"
        :options="[
          {html: size === 'sm' ? `<small>${$t('qrLabelPortrait')}</small>` : $t('qrLabelPortrait'), value: true},
          {html: size === 'sm' ? `<small>${$t('qrLabelLandscape')}</small>` : $t('qrLabelLandscape'), value: false},
        ]"
        :size="size"
        class="mt-50 fucked-up-radio-group"
        @change="onChangePortrait"
      />

      <b-form-group
        v-else
        class="mt-50 mb-0"
      >
        <legend
          :style="{width: '100%', display: 'inline-grid', gridTemplateColumns: labelGridTemplateColumns}"
          :class="`bv-no-focus-ring col-form-label pt-0 col-form-label-${size}`"
        >
          <template v-if="size === 'sm'">
            <small>{{ $t('qrLabelWidth') }}</small>
            <small>{{ $t('qrLabelHeight') }}</small>
          </template>
          <template v-else>
            <span>{{ $t('qrLabelWidth') }}</span>
            <span>{{ $t('qrLabelHeight') }}</span>
          </template>
        </legend>
        <b-input-group :size="size">
          <b-form-input
            ref="width"
            type="number"
            min="0"
            max="999"
            :step="inputStep"
            :value="getSizeParamValue('width')"
            @change="onChangeSide($event, 'width')"
            @blur="$emit('change', {sizeChanged: true})"
          />
          <b-form-input
            ref="height"
            type="number"
            min="0"
            max="999"
            :size="size"
            :step="inputStep"
            :value="getSizeParamValue('height')"
            @change="onChangeSide($event, 'height')"
            @blur="$emit('change', {sizeChanged: true})"
          />
          <template #append>
            <b-dropdown
              :text="value.unit"
              right
              :size="size"
              variant="outline-secondary"
            >
              <b-dropdown-item
                v-for="unit in optionsUnit"
                :key="unit"
                @click.stop="onInputUnit(unit)"
              >
                {{ unit }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-input-group>
      </b-form-group>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BInputGroup,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import {
  validFormats,
  validUnits,
  getBoxSize,
  roundSize,
} from '@/qr/utils'

const formatCustom = 'Custom'

export default {
  name: 'PaperFormatSelector',
  components: {
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BInputGroup,
    BDropdown,
    BDropdownItem,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    size: {
      type: String,
      required: false,
      default: 'md',
    },
    imperialFirst: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: { type: String, required: true },
  },

  data: () => ({
    optionsUnit: [],
    paperFormats: [],
    labelGridTemplateColumns: '',
  }),

  computed: {
    inputStep() {
      return { in: 0.125, cm: 0.1 }[this.value?.unit] || 1
    },

    componentLabel() {
      if (!this.value?.format) return this.label

      const v = this.value.unit ? this.value : getBoxSize(this.value)

      return `${this.label}, ${roundSize(v.width, v.unit)}
      × ${roundSize(v.height, v.unit)}
      ${v.unit}`
    },
  },

  watch: {
    value(v) {
      if (!v.format) {
        this.$nextTick(() => {
          const w = this.$refs.width.$el.clientWidth
          const h = this.$refs.height.$el.clientWidth
          this.labelGridTemplateColumns = `${w}px ${h}px auto`
        })
      }
      return null
    },
  },
  mounted() {
    this.optionsUnit = validUnits(this.imperialFirst)
    this.paperFormats = [formatCustom, ...validFormats(this.imperialFirst)]
    if (this.value.format) this.onInputFormat(this.value.format)
  },

  methods: {
    getFormatValueForSelect() {
      return this.value?.format || formatCustom
    },
    getSizeParamValue(param) {
      if (!this.value || Number.isNaN(this.value[param])) {
        return null
      }
      return roundSize(this.value[param], this.value.unit)
    },
    onInputFormat(event) {
      let size
      if (event === formatCustom) {
        size = this.value.unit ? { ...this.value } : getBoxSize(this.value)
        delete size.format
      } else {
        size = getBoxSize({ format: event, portrait: this.value.portrait })
        const changeOpt = {
          sizeChanged: true,
          unitChanged: this.value.unit !== size.unit ? { old: this.value.unit, new: size.unit } : false,
        }
        this.$nextTick(() => { this.$emit('change', changeOpt) })
      }
      this.$emit('input', size)
    },

    onChangePortrait(isPortrait) {
      const v = this.value
      let size
      if (v.width && v.height) {
        const [short, long] = v.width <= v.height ? [v.width, v.height] : [v.height, v.width]
        size = {
          format: v.format,
          portrait: isPortrait,
          width: isPortrait ? short : long,
          height: isPortrait ? long : short,
          unit: v.unit,
        }
      } else {
        size = getBoxSize({ format: v.format, portrait: isPortrait })
      }
      this.$emit('input', size)
      this.$nextTick(() => { this.$emit('change', { sizeChanged: true }) })
    },
    onChangeSide(event, param) {
      const newSize = { ...this.value }
      newSize[param] = parseFloat(event)
      this.$emit('input', newSize)
    },
    onInputUnit(newUnit) {
      const unitPrev = this.value.unit
      this.$emit('input', getBoxSize(this.value, newUnit))
      const changeOpt = { sizeChanged: false, unitChanged: { old: unitPrev, new: newUnit } }
      this.$nextTick(() => { this.$emit('change', changeOpt) })
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/vue/libs/vue-select.scss'
</style>
<style scoped lang="sass">
::v-deep legend
  text-wrap: nowrap !important
.fucked-up-radio-group
  ::v-deep label
    &:before
      left: 0 !important
</style>
